import React, { forwardRef } from 'react';
import { CommonButtonProps, CommonButton } from './CommonButton';
import { Loader } from './Button.styles';

export interface ButtonProps extends CommonButtonProps {
  type?: 'submit' | 'button';
  disabled?: boolean;
}

export interface LinkButtonProps extends CommonButtonProps {
  href?: string;
}

export const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
  return (
    <CommonButton as="button" {...props}>
      {props.loading ? <Loader size={12} white={true} /> : props.children}
    </CommonButton>
  );
};

export const LinkButton: React.FC<LinkButtonProps> = forwardRef(
  (props: LinkButtonProps, ref) => {
    return (
      <CommonButton as="a" ref={ref} {...props}>
        {props.children}
      </CommonButton>
    );
  }
);
