import { v4 as uuid } from 'uuid';
import * as Analytics from '@app/lib/analytics';

export function getUserId(): string {
  const user = Analytics.user();

  let id;

  if (!user) {
    id = uuid();
  } else {
    id = user?.anonymousId();
  }

  return id;
}
