import mitt from 'mitt';
import * as Sentry from '@sentry/browser';
import * as Analytics from '@app/lib/analytics';
import { getCartFromLocalStorage } from '@app/lib/graphql/utils';

const { NEXT_PUBLIC_LS_CART_KEY } = process.env;

export const emitter: mitt.Emitter = mitt();

export interface SendThirdPartyErrorEventsParamater {
  segment?: any;
  sentry?: any;
}

function sendThirdPartyErrorEvents(err: SendThirdPartyErrorEventsParamater) {
  const cartId = getCartFromLocalStorage(NEXT_PUBLIC_LS_CART_KEY);

  if (err?.sentry) {
    Sentry.withScope(function (scope) {
      scope.setContext('Cart', { id: cartId });
      Sentry.captureException(err?.sentry?.graphqlError, {
        tags: err?.sentry?.tags,
      });
    });
  }

  Analytics.track('genericError', { cart_id: cartId, error: err?.segment });
}

emitter.on('error', sendThirdPartyErrorEvents);
