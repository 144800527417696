import { BaseCart } from '@app/lib/graphql/models';
import { AdjustmentType } from '@app/lib/graphql/schema';
import { getCartAdjustment } from '../getCartAdjustment';
import {
  getDeliveryEstimates,
  DeliveryEstimatesResult,
} from './getDeliveryEstimates';

export const getDeliveryDueDate = async (
  order: BaseCart,
  country: string
): Promise<Date | string> => {
  const estimates: DeliveryEstimatesResult[] = await getDeliveryEstimates(
    order,
    country
  );
  const orderShipping = getCartAdjustment(order, AdjustmentType.Shipping);
  const methodEstimate = estimates.find(
    estimate =>
      estimate?.name.toLowerCase() === orderShipping?.meta?.method.toLowerCase()
  );

  return methodEstimate?.guaranteedDeliveryDate;
};
