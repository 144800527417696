import { useContext } from 'react';
import { AppState, AppStateContext } from './AppStateProvider';

export function useAppState(): AppState {
  const state = useContext<AppState>(AppStateContext);

  if (!state) {
    throw new Error(
      'Could not find "state" in the context or passed in as a prop. ' +
        'Wrap the root component in an <AppStateProvider>, or pass an ' +
        'state instance in via props.'
    );
  }

  return state;
}
