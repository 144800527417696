import React, { memo, forwardRef } from 'react';
import {
  DefaultButton,
  PrimaryButton,
  SecondaryButton,
  TextButton,
} from './Button.styles';

export interface CommonButtonProps extends React.HTMLProps<HTMLAnchorElement> {
  as?: 'a' | 'button';
  ref?: any;
  block?: boolean;
  children?: any;
  className?: string;
  height?: number;
  id?: string;
  loading?: boolean;
  disabled?: boolean;
  style?: any;
  variant?: 'default' | 'primary' | 'secondary' | 'text';
}

export const CommonButton: React.FC<CommonButtonProps> = memo(
  forwardRef(
    (
      {
        as = 'button',
        id = '',
        height = 52,
        block = false,
        children,
        className = '',
        variant = 'default',
        style = {},
        disabled = false,
        loading = false,
        onClick = () => {},
        ...rest
      },
      ref
    ) => {
      let StyledButton;
      switch (variant) {
        case 'primary':
          StyledButton = PrimaryButton;
          break;
        case 'secondary':
          StyledButton = SecondaryButton;
          break;
        case 'text':
          StyledButton = TextButton;
          break;
        default:
          StyledButton = DefaultButton;
      }

      return (
        <StyledButton
          as={as}
          ref={ref}
          id={id}
          height={height}
          block={block}
          className={className}
          onClick={onClick}
          style={style}
          disabled={disabled || loading}
          {...rest}
        >
          {children}
        </StyledButton>
      );
    }
  )
);
