import dayjs, { Dayjs } from 'dayjs';
import {
  FULFILLMENT_HOLIDAYS,
  FULFILLING_WEEKENDS,
} from '@app/config/fulfillment';
import { isSaturday, isSunday } from './isDates';

export const getProductionDate = (orderDate: Dayjs | Date): Dayjs => {
  const productionDate = dayjs(orderDate);

  if (
    (isSaturday(productionDate) && !FULFILLING_WEEKENDS.saturday) ||
    (isSunday(productionDate) && !FULFILLING_WEEKENDS.sunday) ||
    isNonWorkingDay(productionDate)
  ) {
    return getProductionDate(productionDate.add(1, 'day'));
  }

  return productionDate;
};

const isNonWorkingDay = (productionDate: Dayjs) => {
  let isHoliday = false;

  FULFILLMENT_HOLIDAYS.forEach(holiday => {
    if (productionDate.isSame(dayjs(holiday), 'day')) {
      isHoliday = true;
    }
  });

  return isHoliday;
};

export default getProductionDate;
