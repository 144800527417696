// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getPrintVariantSku = (meta: any): string => {
  if (!meta) return null;

  const primaryColor = meta.theme?.primaryColor;

  let color = '';

  switch (primaryColor) {
    case '#051c2c':
      color += 'blue';
      break;
    case '#000000':
      color += 'black';
      break;
    case 'nebula':
      color += 'nebula';
      break;
    case 'kids-astronaut':
      color += 'kids-astronaut';
      break;
    default:
      color += 'blue';
  }

  return `${color}-shadow`;
};
