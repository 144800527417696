import redaxios from 'redaxios';
import { BaseCart } from '@app/lib/graphql/models';
import { ApolloClient } from '@apollo/client';
import { Config } from '@app/config';
import { emitter } from '@app/lib/events';
import * as Analytics from '@app/lib/analytics';
import { get, getFbc, getFbp, removeLocalStorage } from '@app/lib/utils';
import { setCartToLocalStorage } from '@app/lib/graphql/utils';
import { GraphQLError } from './errors';
import {
  AdjustmentType,
  CreateCartDocument,
  AddItemsToCartDocument,
  AddAdjustmentToCartDocument,
  RemoveItemsFromCartDocument,
  UpdateCartDocument,
  UpdateCartItemDocument,
  UpdateCartStatusDocument,
  UpdateCartAndChargeDocument,
  RemoveVoucherDocument,
  CreateBraintreeClientTokenDocument,
  ChargeBraintreeDocument,
  CreateCartInput,
  AddItemsToCartInput,
  AddAdjustmentToCartInput,
  RemoveItemsFromCartInput,
  UpdateCartInput,
  UpdateCartItemInput,
  RemoveAdjustmentFromCartDocument,
  CartStatus,
  ProductsDocument,
  ApproveCartDocument,
} from '@app/lib/graphql/schema';

const NEXT_PUBLIC_PRINTS_URI = process.env.NEXT_PUBLIC_PRINTS_URI;
const NEXT_PUBLIC_LS_CART_KEY = process.env.NEXT_PUBLIC_LS_CART_KEY;

export class Cart extends BaseCart {
  public api: ApolloClient<any>;
  public shopConfig: Config;

  async create(input: Omit<CreateCartInput, 'store'>): Promise<Cart> {
    input.meta = input.meta || {};
    input.meta.userAgent = window?.navigator?.userAgent;

    try {
      const fbp = getFbp();
      const fbc = getFbc(NEXT_PUBLIC_LS_CART_KEY);
      const { params: first_params } = this.getAttributionParamsWithPrefix(
        'first_'
      );
      const { params: last_params } = this.getAttributionParamsWithPrefix(
        'last_'
      );

      if (fbc) {
        input.meta.fbc = fbc;
      }

      if (fbp) {
        input.meta.fbp = fbp;
      }

      if (Object.keys(first_params || {}).length > 0) {
        input.meta = { ...input.meta, ...first_params, ...last_params };
      }

      const response = await this.api.mutate({
        mutation: CreateCartDocument,
        variables: { input: { ...input, store: this.shopConfig.store } },
      });

      const cart = get(response, 'data.createCart', null);
      setCartToLocalStorage(NEXT_PUBLIC_LS_CART_KEY, cart?.id);
      Analytics.SessionCache.setHasUpdatedDsn(true);

      return cart;
    } catch (e) {
      const { errorResponse, graphqlError } = this.getGraphQLErrorFromResponse(
        e
      );

      emitter.emit('error', {
        sentry: { graphqlError, tags: { mutation: 'create_cart' } },
        segment: { error: errorResponse, payload: input },
      });

      throw graphqlError;
    }
  }

  async updateCart(data: Partial<UpdateCartInput['data']>): Promise<Cart> {
    try {
      const response = await this.api.mutate({
        mutation: UpdateCartDocument,
        variables: { input: { id: this.id, data } },
      });

      const cart = get(response, 'data.updateCart', null);

      return cart;
    } catch (e) {
      const { errorResponse, graphqlError } = this.getGraphQLErrorFromResponse(
        e
      );

      emitter.emit('error', {
        sentry: {
          graphqlError,
          tags: { mutation: 'update_cart' },
        },
        segment: {
          error: errorResponse,
          payload: data,
          cart_id: this.id,
        },
      });

      throw graphqlError;
    }
  }

  async updateCartStatus(cartStatus: CartStatus): Promise<Cart> {
    try {
      const response = await this.api.mutate({
        mutation: UpdateCartStatusDocument,
        variables: { input: { id: this.id, status: cartStatus } },
      });

      const cart = get(response, 'data.updateCartStatus', null);

      return cart;
    } catch (e) {
      const { errorResponse, graphqlError } = this.getGraphQLErrorFromResponse(
        e
      );

      emitter.emit('error', {
        sentry: {
          graphqlError,
          tags: { mutation: 'update_cart_status' },
        },
        segment: {
          error: errorResponse,
          payload: cartStatus,
          cart_id: this.id,
        },
      });

      throw graphqlError;
    }
  }

  async requestCancellation(reason: string, comments: string): Promise<Cart> {
    try {
      const response = await this.api.mutate({
        mutation: UpdateCartStatusDocument,
        variables: {
          input: {
            id: this.id,
            status: CartStatus.PendingCancel,
            timeline: {
              meta: { reason, comments },
              text: 'Order cancellation requested by customer.',
            },
          },
        },
      });

      const cart = get(response, 'data.updateCartStatus', null);

      return cart;
    } catch (e) {
      const { errorResponse, graphqlError } = this.getGraphQLErrorFromResponse(
        e
      );

      emitter.emit('error', {
        sentry: {
          graphqlError,
          tags: { mutation: 'update_cart_status' },
        },
        segment: {
          error: errorResponse,
          payload: { status: CartStatus.PendingCancel },
          cart_id: this.id,
        },
      });

      throw graphqlError;
    }
  }

  async addItemsToCart(
    items: Partial<AddItemsToCartInput['items']>
  ): Promise<Cart> {
    try {
      if (this.paidAt) throw new Error('Can not update completed cart.');

      const response = await this.api.mutate({
        mutation: AddItemsToCartDocument,
        variables: { input: { id: this.id, items } },
      });

      const cart = get(response, 'data.addItemsToCart', null);
      const item = get(cart, `items.${cart.items.length - 1}`, null);

      Analytics.track('productAdded', cart, item);

      return cart;
    } catch (e) {
      const { errorResponse, graphqlError } = this.getGraphQLErrorFromResponse(
        e
      );

      emitter.emit('error', {
        sentry: {
          graphqlError,
          tags: { mutation: 'add_items_to_cart' },
        },
        segment: {
          error: errorResponse,
          payload: items,
          cart_id: this.id,
        },
      });

      throw graphqlError;
    }
  }

  async addAdjustmentToCart(
    data: Partial<AddAdjustmentToCartInput['data']>
  ): Promise<Cart> {
    try {
      const response = await this.api.mutate({
        mutation: AddAdjustmentToCartDocument,
        variables: { input: { id: this.id, data } },
      });

      const cart = get(response, 'data.addAdjustmentToCart', null);

      return cart;
    } catch (e) {
      const { errorResponse, graphqlError } = this.getGraphQLErrorFromResponse(
        e
      );

      emitter.emit('error', {
        sentry: {
          graphqlError,
          tags: { mutation: 'add_adjustment_to_cart' },
        },
        segment: {
          error: errorResponse,
          payload: data,
          cart_id: this.id,
        },
      });

      throw graphqlError;
    }
  }

  async updateCartItem(
    item: string,
    data: Partial<UpdateCartItemInput['data']>
  ): Promise<Cart> {
    try {
      const response = await this.api.mutate({
        mutation: UpdateCartItemDocument,
        variables: { input: { id: this.id, item, data } },
      });

      const cart = get(response, 'data.updateCartItem', null);

      return cart;
    } catch (e) {
      const { errorResponse, graphqlError } = this.getGraphQLErrorFromResponse(
        e
      );

      emitter.emit('error', {
        sentry: {
          graphqlError,
          tags: { mutation: 'update_cart_item' },
        },
        segment: {
          error: errorResponse,
          payload: { item, data },
          cart_id: this.id,
        },
      });

      throw graphqlError;
    }
  }

  async removeVoucher(): Promise<Cart> {
    try {
      if (!this.couponCode) throw new Error('Cart has no voucher.');

      const response = await this.api.mutate({
        mutation: RemoveVoucherDocument,
        variables: { input: { id: this.id, code: this.couponCode } },
      });

      const cart = get(response, 'data.removeVoucher', null);

      return cart;
    } catch (e) {
      const { errorResponse, graphqlError } = this.getGraphQLErrorFromResponse(
        e
      );

      emitter.emit('error', {
        sentry: { graphqlError, tags: { mutation: 'remove_voucher' } },
        segment: { error: errorResponse, cart_id: this.id },
      });

      throw graphqlError;
    }
  }

  async removeItemsFromCart(
    items: Partial<RemoveItemsFromCartInput['items']>
  ): Promise<Cart> {
    try {
      if (this.paidAt) throw new Error('Can not update completed cart.');

      const response = await this.api.mutate({
        mutation: RemoveItemsFromCartDocument,
        variables: { input: { id: this.id, items } },
      });

      const cart = get(response, 'data.removeItemsFromCart', null);

      return cart;
    } catch (e) {
      const { errorResponse, graphqlError } = this.getGraphQLErrorFromResponse(
        e
      );

      emitter.emit('error', {
        sentry: {
          graphqlError,
          tags: { mutation: 'remove_items_from_cart' },
        },
        segment: {
          error: errorResponse,
          payload: items,
          cart_id: this.id,
        },
      });

      throw graphqlError;
    }
  }

  async approve(): Promise<Cart> {
    try {
      if (!this.paidAt)
        throw new Error('Cart can not be approved until paid for.');

      const response = await this.api.mutate({
        mutation: ApproveCartDocument,
        variables: { input: { id: this.id } },
      });

      const cart = get(response, 'data.approveCart', null);

      return cart;
    } catch (e) {
      const { errorResponse, graphqlError } = this.getGraphQLErrorFromResponse(
        e
      );

      emitter.emit('error', {
        sentry: {
          graphqlError,
          tags: { mutation: 'approve_cart' },
        },
        segment: {
          error: errorResponse,
          cart_id: this.id,
        },
      });

      throw graphqlError;
    }
  }

  async initializeShipping(): Promise<Cart> {
    const shippingRates = await this.deliveryEstimates('us');
    const standardRate = shippingRates.find(
      rate => rate.key?.toLowerCase() === 'standard'
    );

    return this.addAdjustmentToCart({
      type: AdjustmentType.Shipping,
      amount: standardRate.price,
      meta: {
        deliveryDate: standardRate.guaranteedDeliveryDate,
        method: standardRate.method.toUpperCase(),
        name: standardRate.name,
        range: standardRate.range,
      },
    });
  }

  async removeShipping(): Promise<Cart> {
    try {
      const shipping = this.shipping;

      if (!shipping) return;

      const response = await this.api.mutate({
        mutation: RemoveAdjustmentFromCartDocument,
        variables: { input: { id: this.id, adjustment: shipping.id } },
      });

      return get(response, 'data.removeAdjustmentFromCart', null);
    } catch (e) {
      const { errorResponse, graphqlError } = this.getGraphQLErrorFromResponse(
        e
      );

      emitter.emit('error', {
        sentry: {
          graphqlError,
          tags: { mutation: 'remove_adjustment_from_cart' },
        },
        segment: { error: errorResponse, cart_id: this.id },
      });

      throw graphqlError;
    }
  }

  async createBraintreeToken(): Promise<string> {
    if (!this.id) throw new Error('Your cart is empty.');

    try {
      const response = await this.api.mutate({
        mutation: CreateBraintreeClientTokenDocument,
        variables: { input: { id: this.id } },
      });

      return get(response, 'data.createBraintreeClientToken.clientToken', null);
    } catch (e) {
      const { errorResponse, graphqlError } = this.getGraphQLErrorFromResponse(
        e
      );

      emitter.emit('error', {
        sentry: {
          graphqlError,
          tags: { mutation: 'create_braintree_token' },
        },
        segment: { error: errorResponse, cart_id: this.id },
      });

      throw graphqlError;
    }
  }

  async chargeBraintree(paymentNonce: string): Promise<Cart> {
    try {
      const response = await this.api.mutate({
        mutation: ChargeBraintreeDocument,
        variables: {
          input: { id: this.id, paymentMethodId: paymentNonce },
        },
      });

      const cart = get(response, 'data.chargeBraintree', null);

      Analytics.track('orderCompleted', cart);
      removeLocalStorage(NEXT_PUBLIC_LS_CART_KEY);

      return cart;
    } catch (e) {
      emitter.emit('error', {
        sentry: {
          graphqlError: e,
          tags: { mutation: 'charge_braintree' },
        },
        segment: { error: e?.message, cart_id: this.id },
      });

      throw e;
    }
  }

  async updateCartAndCharge(
    updateCartInput: Partial<UpdateCartInput['data']>,
    paymentNonce: string
  ): Promise<Cart> {
    try {
      const response = await this.api.mutate({
        mutation: UpdateCartAndChargeDocument,
        variables: {
          updateCartInput: { id: this.id, data: { ...updateCartInput } },
          chargeBraintreeInput: { id: this.id, paymentMethodId: paymentNonce },
        },
      });

      const cart = get(response, 'data.chargeBraintree', null);

      Analytics.track('orderCompleted', cart);
      removeLocalStorage(NEXT_PUBLIC_LS_CART_KEY);

      return cart;
    } catch (e) {
      emitter.emit('error', {
        sentry: {
          graphqlError: e,
          tags: { mutation: 'charge_braintree' },
        },
        segment: { error: e?.message, cart_id: this.id },
      });

      throw e;
    }
  }

  async updateLastTouchParams(): Promise<Cart> {
    try {
      Analytics.SessionCache.setHasUpdatedDsn(true);

      const { params, isDirect } = this.getAttributionParamsWithPrefix('last_');
      const meta = {};

      Object.keys(this.meta || {}).forEach(key => {
        if (isDirect && key.includes('last_')) {
          meta[key] = null;
        } else {
          meta[key] = this.meta[key];
        }
      });

      const response = await this.api.mutate({
        mutation: UpdateCartDocument,
        variables: {
          input: {
            id: this.id,
            data: { meta: { ...meta, ...params } },
          },
        },
      });

      const cart = get(response, 'data.updateCart', null);

      return cart;
    } catch (e) {
      emitter.emit('error', {
        sentry: {
          graphqlError: e,
          tags: { mutation: 'update_cart' },
        },
        segment: { error: e?.message, cart_id: this.id },
      });

      throw e;
    }
  }

  async getPrintItemFile(itemId: string): Promise<string[] | null> {
    const item = this.items.find(item => item.id === itemId);
    const metaUpdatedAt = new Date(item.metaUpdatedAt).getTime();

    const filenamePDF = `${this.id}_${item.id}_${metaUpdatedAt}.pdf`;
    const filenamePNG = `${this.id}_${item.id}_${metaUpdatedAt}.png?fm=png&q=100&w=1&h=1&dl=${this.id}_${item.id}_${metaUpdatedAt}.png`;

    try {
      const res = await redaxios.get(
        `${NEXT_PUBLIC_PRINTS_URI?.toString()}/exists/${filenamePDF}`,
        { responseType: 'json' }
      );

      if (!res.data.exists) {
        return null;
      }

      return [
        `${NEXT_PUBLIC_PRINTS_URI}/${filenamePDF}`,
        `${NEXT_PUBLIC_PRINTS_URI}/${filenamePNG}`,
      ];
    } catch (e) {
      return null;
    }
  }

  async getAllDigitalPrintFiles(): Promise<(string[] | null)[]> {
    const digitalPrints = this.items.filter(item => item.isDigitalPrint);

    const reqs = digitalPrints.map(print => this.getPrintItemFile(print.id));

    try {
      return Promise.all(reqs);
    } catch (e) {
      return [];
    }
  }

  async getShippingEstimates(country: string): Promise<any> {
    try {
      const { data: { products = [] } = {} } = await this.api.query({
        query: ProductsDocument,
      });

      const estimates = await this.deliveryEstimates(country);
      const cartItems = this.items.map(item => item.sku);

      const productsInCart = products?.filter(
        p => cartItems.includes(p.sku) && p.meta?.availableShipping?.length > 0
      );

      const availableShipping = productsInCart?.reduce(
        (acc, curr) =>
          acc.meta?.availableShipping?.length <
          curr.meta?.availableShipping?.length
            ? acc
            : curr,
        {}
      ).meta?.availableShipping;

      return estimates.filter(e => availableShipping?.includes(e.key)) || [];
    } catch (e) {
      emitter.emit('error', {
        sentry: {
          graphqlError: e,
          tags: { mutation: 'get_shipping_estimates' },
        },
        segment: { error: e?.message, cart_id: this.id },
      });

      throw e;
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  private getGraphQLErrorFromResponse(error: any): any {
    const message = get(
      error,
      'networkError.result.errors.0.message',
      error?.message
    );

    return {
      errorResponse: message,
      graphqlError: new GraphQLError(message),
    };
  }

  // url parameters are saved in localstorage for later use; retrieve UTM params
  private getAttributionParamsWithPrefix(
    prefix = ''
  ): {
    params: any;
    isDirect: boolean;
  } {
    const params = Analytics.SessionCache.getStorageParams();
    const isDirect = Analytics.SessionCache.isSourceDirect();
    const att_params: any = {};

    Object.keys(params).forEach(key => {
      if (
        key?.toLowerCase()?.includes('utm_') ||
        key?.toLowerCase()?.includes('aa_')
      ) {
        att_params[prefix + key] = params[key];
      }
    });

    if (!att_params?.[prefix + 'utm_source']) {
      att_params[prefix + 'utm_source'] = 'direct';
    }

    if (!att_params?.[prefix + 'aa_source']) {
      att_params[prefix + 'aa_source'] = 'direct';
    }

    return { params: att_params, isDirect };
  }
}

export default Cart;
