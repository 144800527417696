import { PRINT_META_KEY, PRINT_CLIENT_URL } from '../BaseCartItem';
import { getPrintVariantSku } from './getPrintVariantSku';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const normalizePrintMeta = (meta: any): any => ({
  variant: getPrintVariantSku(meta),
  [PRINT_META_KEY]: {
    width: meta?.size?.width,
    height: meta?.size?.height,
    url: PRINT_CLIENT_URL,
    options: meta,
  },
});
