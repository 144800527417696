import React, { createContext, useEffect, useMemo, useContext } from 'react';
import { useRouter } from 'next/router';
import * as Sentry from '@sentry/browser';
import * as Analytics from '@app/lib/analytics';
import { useApolloClient } from '@apollo/client';
import { useShop } from '@app/lib/shop';
import { SessionCache } from '@app/lib/analytics';
import { useAppState } from '@app/contexts/app';
import { useCartQuery } from '@app/lib/graphql/schema';
import { get, removeLocalStorage, useWindowFocus } from '@app/lib/utils';
import { Cart } from '@app/lib/graphql/models';
import {
  getCartFromLocalStorage,
  plainToCartClass,
} from '@app/lib/graphql/utils';

export interface CartContextType {
  cart: Cart;
  error: any;
  loading: boolean;
}

const { NEXT_PUBLIC_LS_CART_KEY } = process.env;

export const CartContext = createContext<CartContextType>(null);

export const CartProvider: React.FC = ({ children }) => {
  const router = useRouter();
  const shop = useShop();
  const app = useAppState();
  const apollo = useApolloClient();
  const windowFocused = useWindowFocus();
  const cartId = router.query.cartId
    ? router.query.cartId
    : getCartFromLocalStorage(NEXT_PUBLIC_LS_CART_KEY);

  const { data, loading, error, refetch } = useCartQuery({
    skip: !cartId,
    ssr: false,
    variables: { id: cartId },
    fetchPolicy: 'cache-first',
    onCompleted: data => {
      const { email, id: cartId } = data?.cart || {};

      Sentry.setUser({ email, cartId });
    },
  });

  if (error) {
    Analytics.track('genericError', error);
  }

  const cart = useMemo<Cart>(() => {
    const cart = plainToCartClass({ ...get(data, 'cart', {}) });

    cart.api = apollo;
    cart.shopConfig = shop;

    if (cartId === cart?.id && cart.paidAt) {
      removeLocalStorage(NEXT_PUBLIC_LS_CART_KEY);
    }

    return cart;
  }, [data, loading, cartId]);

  useEffect(() => {
    if (cart?.items?.length > 0) {
      app.updateAppState({ user: { hasAddedFirstProduct: true } });
    }
  }, [cart.items]);

  // update cart on rehydration with last touch utm/aa parameters
  useEffect(() => {
    const hasSetTrackingParams = SessionCache.getHasUpdatedDsn();

    const updateCartParams = async () => {
      await cart.updateLastTouchParams();
    };

    if (cart.id && !cart.paidAt && !hasSetTrackingParams) {
      updateCartParams();
    }
  }, [cart]);

  useEffect(() => {
    const onFocus = () => refetch();
    if (windowFocused && cart.id && !loading) onFocus();
  }, [windowFocused]);

  return (
    <CartContext.Provider value={{ cart, loading, error }}>
      {children}
    </CartContext.Provider>
  );
};

export function useCart(): CartContextType {
  return useContext(CartContext);
}
