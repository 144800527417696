import { Cart, BaseCartItem } from '@app/lib/graphql/models';
import { Cart as CartSchema } from '@app/lib/graphql/schema';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export function plainToCartClass(data: CartSchema): Cart {
  const items = [];

  if (data?.items?.length) {
    data?.items.forEach(item => {
      items.push(new BaseCartItem(item));
    });

    delete data?.items;
  }

  return new Cart({ ...data, items });
}
