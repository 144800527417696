import dayjs from 'dayjs';
import { BaseCart } from '@app/lib/graphql/models';
import { getShippingRates } from '@app/config/shipping/rates';
import { getDeliveryDate } from './getDeliveryDate';
import { getProductionDate } from './getProductionDate';

export interface DeliveryEstimatesResult {
  name: string;
  method: string;
  key: string;
  range: number[];
  price: number;
  earliestDeliveryDate: string;
  guaranteedDeliveryDate: string;
  arrivesBeforeHoliday: boolean;
}

export const getDeliveryEstimates = async (
  order: BaseCart,
  country: string
): Promise<DeliveryEstimatesResult[] | null> => {
  const SHIPPING_RATES = await getShippingRates();

  if (!order) return null;

  let estimates = [];
  const framed = order.hasFrame;
  const orderDate = dayjs(order.paidAt ? new Date(order.paidAt) : new Date());
  const productionDate = getProductionDate(orderDate.add(1, 'day'));

  SHIPPING_RATES.forEach(method => {
    const countryRate = method?.rates[country]
      ? method?.rates[country]
      : method?.rates?.other;

    if (!countryRate) return null;

    const price = framed
      ? countryRate?.framed?.price
      : countryRate?.unframed?.price;

    const earlyDeliveryOffset = framed
      ? countryRate.framed?.fastest
      : countryRate.unframed?.fastest;
    const guaranteedDeliveryOffset = framed
      ? countryRate.framed?.slowest
      : countryRate.unframed?.slowest;
    const holidayOffset = framed
      ? countryRate.framed?.holidayOffset
      : countryRate.unframed?.holidayOffset;

    const earliestDeliveryDate = getDeliveryDate(
      productionDate,
      earlyDeliveryOffset,
      method.name.toLowerCase()
    );

    const guaranteedDeliveryDate = getDeliveryDate(
      productionDate,
      guaranteedDeliveryOffset + holidayOffset,
      method.name.toLowerCase()
    );

    estimates.push({
      name: method.name,
      method: method.method,
      key: method.key,
      range: [earlyDeliveryOffset, guaranteedDeliveryOffset],
      price,
      earliestDeliveryDate,
      guaranteedDeliveryDate,
      arrivesBeforeHoliday: dayjs(guaranteedDeliveryDate).isBefore(
        '2021-12-25'
      ),
    });
  });

  if (!order.hasFulfillableItems) {
    estimates = estimates.filter(
      estimate => estimate.method.toLowerCase() === 'standard'
    );
  }

  return estimates;
};
