import qs from 'qs';
import andalways from '@andalways/analytics-js';
import { get } from '@app/lib/utils';
import { Cart, Item } from '@app/lib/graphql/models';
import { getItemPrintMeta } from '@app/lib/graphql/models/utils';

export const affiliation = 'OverOurMoon Store';
export const currency = 'USD';

export class Analytics {
  private segment: SegmentAnalytics.AnalyticsJS;
  private readonly category = 'moon_maps';

  constructor() {
    this.segment = window?.analytics;
  }

  public initialize(cart: Cart): void {
    const search = get(window, 'location.search', '');
    const params = qs.parse(search.split('?')[1], {
      ignoreQueryPrefix: true,
    });
    const email = get(params, 'email', null);

    if (email && cart?.customer?.id) {
      andalways.identify(cart.customer.id, { email });
      this.segment.identify(cart?.customer?.id, {
        email,
        tags: ['OverOurMoon'],
        tagsReset: 0,
      });
    }
  }

  public cartRehydration(cart: Cart): void {
    const anonymousId = andalways.user().getAnonymousId();

    if (cart?.customer?.id) {
      andalways.identify(cart.customer.id, { email: cart?.email });
      this.segment.identify(
        cart.customer.id,
        { email: cart.email, tags: ['OverOurMoon'], tagsReset: 0 },
        { anonymousId }
      );
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public createCart(cart: Cart, userConfig: any): void {
    const anonymousId = andalways.user().getAnonymousId();
    const item = get(cart, 'items.0', {});

    if (cart?.customer?.id) {
      andalways.identify(cart.customer.id, { email: cart?.email });

      this.segment.identify(
        cart.customer.id,
        {
          email: cart?.email,
          gender: userConfig?.gender,
          tags: ['OverOurMoon'],
          tagsReset: 0,
        },
        {
          anonymousId,
          integrations: {
            Klaviyo: {
              listId: 'TD8L8U',
              confirmOptin: false,
            },
          },
        },
        () => {
          if (item) {
            this.firstProductAdded(cart, item);
            this.productAdded(cart, item);
          }
        }
      );
    }
  }

  public cartViewed(cart: Cart): void {
    const products = this.buildProducts(cart);

    andalways.track('Cart Viewed', {
      currency,
      products,
      cart_id: cart.id,
      email: cart?.email,
      value: +cart.total / 100,
    });

    this.segment.track('Cart Viewed', {
      cart_id: cart.id,
      email: cart?.email,
      cart: { id: cart?.id },
      currency,
      value: +cart.total / 100,
      products,
    });
  }

  public productViewed(cart: Cart, item: Item): void {
    andalways.track('Product Viewed', {
      currency,
      cart_id: cart?.id,
      product_id: item.sku,
      category: this.category,
      quantity: 1,
      sku: item.sku,
      price: item.unitPrice / 100,
      value: item.unitPrice / 100,
    });

    this.segment.track('Product Viewed', {
      currency,
      cart_id: cart?.id,
      cart: { id: cart?.id },
      email: cart?.email,
      product_id: item.sku,
      category: this.category,
      quantity: 1,
      sku: item.sku,
      price: item.unitPrice / 100,
      value: item.unitPrice / 100,
    });
  }

  public frameOptionsViewed(cart: Cart, item: Item): void {
    andalways.track('Frame Options Viewed', {
      cart_id: cart?.id,
      product_id: item?.sku,
      category: 'frames',
      item: item?.id,
      currency,
    });

    this.segment.track('Frame Options Viewed', {
      cart_id: cart?.id,
      cart: { id: cart?.id },
      email: cart?.email,
      product_id: item?.sku,
      category: 'frames',
      item: item?.id,
      currency,
    });
  }

  public productAdded(cart: Cart, item: Item): void {
    const print_variant_sku = item?.meta?.print_variant_sku
      ? item?.meta?.print_variant_sku
      : this.getProductVariant(getItemPrintMeta(item));

    andalways.track('Product Added', {
      cart_id: cart.id,
      product_id: item.sku,
      category: this.category,
      quantity: 1,
      sku: item.sku,
      variant: print_variant_sku,
      price: item.unitPrice / 100,
      value: item.unitPrice / 100,
      currency,
    });

    this.segment.track('Product Added', {
      cart_id: cart.id,
      cart: { id: cart?.id },
      email: cart?.email,
      product_id: item.sku,
      category: this.category,
      quantity: 1,
      sku: item.sku,
      variant: print_variant_sku,
      price: item.unitPrice / 100,
      value: item.unitPrice / 100,
      currency,
    });
  }

  public firstProductAdded(cart: Cart, item: Item): void {
    const print_variant_sku = item?.meta?.print_variant_sku
      ? item?.meta?.print_variant_sku
      : this.getProductVariant(getItemPrintMeta(item));

    andalways.track('First Product Added', {
      cart_id: cart.id,
      product_id: item.sku,
      category: this.category,
      quantity: 1,
      sku: item.sku,
      variant: print_variant_sku,
      price: item.unitPrice / 100,
      value: item.unitPrice / 100,
      currency,
    });

    this.segment.track('First Product Added', {
      cart_id: cart.id,
      cart: { id: cart?.id },
      email: cart?.email,
      product_id: item.sku,
      category: this.category,
      quantity: 1,
      sku: item.sku,
      variant: print_variant_sku,
      price: item.unitPrice / 100,
      value: item.unitPrice / 100,
      currency,
    });
  }

  public productRemoved(cart: Cart, item: Item): void {
    const print_variant_sku = item?.meta?.print_variant_sku
      ? item?.meta?.print_variant_sku
      : this.getProductVariant(getItemPrintMeta(item));

    andalways.track('Product Removed', {
      currency,
      cart_id: cart.id,
      product_id: item.sku,
      category: this.category,
      quantity: 1,
      sku: item.sku,
      variant: print_variant_sku,
      price: item.unitPrice / 100,
      value: item.unitPrice / 100,
    });

    this.segment.track('Product Removed', {
      cart_id: cart.id,
      cart: { id: cart?.id },
      email: cart?.email,
      product_id: item.sku,
      category: this.category,
      quantity: 1,
      sku: item.sku,
      variant: print_variant_sku,
      price: item.unitPrice / 100,
      value: item.unitPrice / 100,
      currency,
    });
  }

  public checkoutStarted(cart: Cart): void {
    const products = this.buildProducts(cart);

    andalways.track('Checkout Started', {
      products,
      currency,
      cart_id: cart?.id,
      value: +cart.total / 100,
      revenue: +cart.total / 100,
      shipping: +cart.total / 100,
      tax: cart.taxAmount,
    });

    this.segment.track('Checkout Started', {
      order_id: cart.id,
      cart: { id: cart?.id },
      email: cart?.email,
      affiliation,
      value: +cart.total / 100,
      revenue: +cart.total / 100,
      shipping: +cart.total / 100,
      tax: cart.taxAmount,
      products,
      currency,
    });
  }

  public checkoutStepViewed(cart: Cart, step: number): void {
    andalways.track('Checkout Step Viewed', {
      cart_id: cart?.id,
      email: cart.email,
      step,
    });

    this.segment.track('Checkout Step Viewed', {
      checkout_id: cart.id,
      cart: { id: cart?.id },
      email: cart.email,
      step,
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public checkoutStepCompleted(cart: Cart, trackingData: any): void {
    const step = trackingData?.step;
    const anonymousId = andalways.user().getAnonymousId();

    if (step === 1 && cart?.customer?.id) {
      andalways.identify(cart.customer.id, { email: cart?.email });
      this.segment.identify(
        cart?.customer?.id,
        {
          email: cart.email,
          firstName: cart.firstName,
          lastName: cart.lastName,
          phone: cart.phone,
          tags: ['OverOurMoon'],
          tagsReset: 0,
        },
        {
          anonymousId,
          integrations: {
            Klaviyo: {
              listId: 'TD8L8U',
              confirmOptin: false,
            },
          },
        }
      );
    }

    if (step === 1) {
      andalways.track('Checkout Customer Complete', {
        cart_id: cart?.id,
        email: cart.email,
        ...trackingData,
      });

      this.segment.track('Checkout Customer Complete', {
        checkout_id: cart.id,
        cart: { id: cart?.id },
        email: cart.email,
        ...trackingData,
      });
    }

    if (step === 2) {
      andalways.track('Checkout Shipping Complete', {
        cart_id: cart?.id,
        email: cart.email,
        ...trackingData,
      });

      this.segment.track('Checkout Shipping Complete', {
        checkout_id: cart.id,
        cart: { id: cart?.id },
        email: cart.email,
        ...trackingData,
      });
    }

    andalways.track('Checkout Step Completed', {
      cart_id: cart?.id,
      email: cart.email,
      ...trackingData,
    });

    this.segment.track('Checkout Step Completed', {
      checkout_id: cart.id,
      cart: { id: cart?.id },
      email: cart.email,
      ...trackingData,
    });
  }

  // TO-DO FIX
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public paymentInfoEntered(cart: Cart, trackingData: any): void {
    andalways.track('Checkout Step Completed', {
      cart_id: cart?.id,
      email: cart.email,
      ...trackingData,
    });

    this.segment.track('Checkout Step Completed', {
      checkout_id: cart.id,
      cart: { id: cart?.id },
      email: cart.email,
      ...trackingData,
    });
  }

  /**
   * Order Completed
   * @param {Cart} cart
   * @returns {void}
   */
  public orderCompleted(cart: Cart): void {
    const products = this.buildProducts(cart);

    andalways.track('Order Completed', {
      products,
      currency,
      cart_id: cart?.id,
      email: cart.email,
      firstName: cart.firstName,
      lastName: cart.lastName,
      fullName: cart.fullName,
      total: +cart.total / 100,
      value: +cart.total / 100,
      revenue: +cart.total / 100,
      shipping: +cart?.shipping?.amount / 100,
      tax: +cart.taxAmount / 100,
    });

    this.segment.track('Order Completed', {
      checkout_id: cart.id,
      cart: { id: cart?.id },
      order_id: cart.id,
      email: cart.email,
      firstName: cart.firstName,
      lastName: cart.lastName,
      fullName: cart.fullName,
      affiliation,
      total: +cart.total / 100,
      value: +cart.total / 100,
      revenue: +cart.total / 100,
      shipping: +cart?.shipping?.amount / 100,
      tax: +cart.taxAmount / 100,
      products,
      currency,
    });
  }

  public couponEntered(cart: Cart, couponCode: string): void {
    this.segment.track('Coupon Entered', {
      cart_id: cart.id,
      cart: { id: cart?.id },
      order_id: cart.id,
      coupon_id: couponCode,
    });
  }

  public couponApplied(cart: Cart, voucher: string): void {
    this.segment.track('Coupon Applied', {
      cart_id: cart.id,
      cart: { id: cart?.id },
      order_id: cart.id,
      coupon_id: voucher,
      coupon_name: voucher,
    });
  }

  public couponDenied(cart: Cart, voucher: string): void {
    this.segment.track('Coupon Denied', {
      cart_id: cart.id,
      cart: { id: cart?.id },
      order_id: cart.id,
      coupon_id: voucher,
      coupon_name: voucher,
      reason: 'Not Found',
    });
  }

  public couponRemoved(cart: Cart, voucher: string): void {
    this.segment.track('Coupon Removed', {
      cart_id: cart.id,
      cart: { id: cart?.id },
      order_id: cart.id,
      coupon_id: voucher,
      coupon_name: voucher,
    });
  }

  public questionaireViewed(version: string): void {
    const anonymousId = andalways.user().getAnonymousId();

    andalways.track('Create Questionnaire Viewed', {
      anonymousId,
      version,
    });

    this.segment.track('Create Questionnaire Viewed', {
      anonymousId,
      version,
    });
  }

  public questionaireClosedWithoutSaving(version: string): void {
    const anonymousId = this.segment.user().anonymousId();

    andalways.track('Create Questionnaire Closed', { anonymousId, version });
    this.segment.track('Create Questionnaire Closed', {
      anonymousId,
      version,
    });
  }

  public questionaireSubmitted(
    cart: Cart,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    questionData: any
  ): void {
    const { design_for, design_occasion, version } = questionData;
    const anonymousId = andalways.user().getAnonymousId();

    const payload = {
      email: cart.email,
      cart_id: cart.id,
      design_for,
      design_occasion,
      design_questionaire_version: version,
    };

    if (cart?.customer?.id) {
      andalways.identify(cart.customer.id, payload);
      this.segment.identify(cart.customer.id, payload, {
        anonymousId,
        integrations: { Klaviyo: { listId: 'TD8L8U', confirmOptin: false } },
      });
    }

    andalways.track('Create Questionnaire Submitted', {
      email: cart.email,
      cart_id: cart.id,
      design_for,
      design_occasion,
      version,
      anonymousId,
    });

    this.segment.track('Create Questionnaire Submitted', {
      email: cart.email,
      cart_id: cart.id,
      cart: { id: cart?.id },
      design_for,
      design_occasion,
      version,
      anonymousId,
    });
  }

  public orderPreapproved(cart: Cart): void {
    const products = this.buildProducts(cart);

    andalways.track('Order Preapproved', {
      products,
      currency,
      cart_id: cart.id,
      email: cart.email,
      firstName: cart.firstName,
      lastName: cart.lastName,
      fullName: cart.fullName,
      total: +cart.total / 100,
      value: +cart.total / 100,
      revenue: +cart.total / 100,
      shipping: +cart?.shipping?.amount / 100,
      tax: +cart.taxAmount / 100,
    });

    this.segment.track('Order Preapproved', {
      checkout_id: cart.id,
      cart: { id: cart?.id },
      order_id: cart.id,
      email: cart.email,
      firstName: cart.firstName,
      lastName: cart.lastName,
      fullName: cart.fullName,
      affiliation,
      total: +cart.total / 100,
      value: +cart.total / 100,
      revenue: +cart.total / 100,
      shipping: +cart?.shipping?.amount / 100,
      tax: +cart.taxAmount / 100,
      products,
      currency,
    });
  }

  public shippingAddressVerification(data: {
    cart: Cart;
    parsedAddress: any;
  }): void {
    this.segment.track('Shipping Address Verification', {
      checkout_id: get(data, 'cart.id', null),
      cart: { id: get(data, 'cart.id', null) },
      order_id: get(data, 'cart.id', null),
      shippingAddress: {
        ...get(data, 'parsedAddress', {}),
      },
    });
  }

  public expressCheckoutMethodLoaded(cart: Cart, method: string): void {
    this.segment.track('Express Checkout Method Loaded', {
      cart_id: cart.id,
      cart: { id: cart?.id },
      order_id: cart.id,
      method,
    });
  }

  public expressCheckoutStarted(cart: Cart, method: string): void {
    this.segment.track('Express Checkout Started', {
      cart_id: cart.id,
      cart: { id: cart?.id },
      order_id: cart.id,
      method,
    });
  }

  public expressCheckoutCompleted(cart: Cart, method: string): void {
    this.segment.track('Express Checkout Completed', {
      cart_id: cart.id,
      cart: { id: cart?.id },
      order_id: cart.id,
      method,
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public requestCancellation(cart: Cart, formData: any): void {
    this.segment.track('Request Cancellation', {
      order_id: cart.id,
      cart: { id: cart?.id },
      affiliation,
      ...formData,
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public apiError(errors: any): void {
    const apiErrors = [];

    errors.map(({ message, locations, path }) =>
      apiErrors.push(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations
        )}, Path: ${path}, Page: ${window?.location?.href}`
      )
    );

    this.error('API Error', apiErrors, 'api-error');
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public networkError(error: any): void {
    this.error('Network Error', error, 'network-error');
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public appError(errors: any): void {
    this.error('App Client Error', errors, 'app-client-error');
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public genericError(errors: any): void {
    this.error('App Error', errors, 'app-error');
  }

  private error(type: string, errors: any, key?: string): void {
    andalways.trackError('Error', { type, errors, key });
    this.segment.track('Error', {
      type,
      errors,
      key,
      page: window?.location?.href,
    });
  }

  private buildProducts(cart: Cart) {
    const products = [];

    cart.items.forEach((item: Item) => {
      const { sku, unitPrice, name } = item;

      products.push({
        product_id: sku,
        sku,
        name,
        variant: this.getProductVariant(getItemPrintMeta(item)),
        price: unitPrice / 100,
        quantity: 1,
        category: item.category,
      });
    });

    return products;
  }

  private getProductVariant(meta: any): string | null {
    if (!meta) return null;

    const primaryColor = meta?.theme?.primaryColor ?? '';

    let color = '';

    switch (primaryColor) {
      case '#051c2c':
        color += 'blue';
        break;
      case '#000000':
        color += 'black';
        break;
      default:
        color += 'blue';
    }

    return `${color}-shadow`;
  }
}
