import styled from 'styled-components';
import { LoaderIcon } from '../Loader/Loader.styles';

interface ButtonProps {
  block?: boolean;
  height?: number;
}

export const Loader = styled(LoaderIcon)``;

export const Button = styled.div<ButtonProps>`
  ${props => props.theme.buttons.common}
  width: ${props => (props.block ? '100%' : 'auto')};
  height: ${props => props.height}px;
  line-height: ${props => props.height}px;
  padding-left: ${props => Math.round(props.height / 1.8)}px;
  padding-right: ${props => Math.round(props.height / 1.8)}px;

  &:disabled,
  &[disabled] {
    opacity: 1;
  }

  ${Loader} {
    border-top-color: #ffffff;
    border-right-color: #ffffff;
    border-bottom-color: #ffffff;
  }
`;

export const DefaultButton = styled(Button)<ButtonProps>`
  ${(props: any) => props.theme?.buttons?.default}

  ${Loader} {
    border-top-color: #1d435e;
    border-right-color: #1d435e;
    border-bottom-color: #1d435e;
  }
`;

export const PrimaryButton = styled(Button)<ButtonProps>`
  ${(props: any) => props.theme?.buttons?.primary}
`;

export const SecondaryButton = styled(Button)<ButtonProps>`
  ${(props: any) => props.theme?.buttons?.secondary}
`;

export const TextButton = styled(Button)<ButtonProps>`
  ${(props: any) => props.theme?.buttons?.text}

  ${Loader} {
    border-top-color: #1d435e;
    border-right-color: #1d435e;
    border-bottom-color: #1d435e;
  }
`;
