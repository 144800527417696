import { get } from '@app/lib/utils';
import { AdjustmentType } from '@app/lib/graphql/schema';
import { getCartAdjustment } from './getCartAdjustment';
import { BaseCartItem } from '../BaseCartItem';
import { BaseCart } from '../BaseCart';

export const getCartSavings = (cart: BaseCart): number => {
  let savings = 0;
  let items = get(cart, 'items', []);

  items = items.filter(item => item.category !== 'addons');

  items.forEach((item: BaseCartItem) => {
    savings += (item.retailPrice - item.unitPrice) * item.quantity;
  });

  const discount = getCartAdjustment(cart, AdjustmentType.Discount);

  if (discount) savings += Math.abs(discount.amount);

  return savings;
};
