import { get, formatCurrency } from '@app/lib/utils';
import {
  Adjustment,
  AdjustmentType,
  ApprovedBy,
  Cart,
  CartStatus,
} from '@app/lib/graphql/schema';
import { BaseCartItem } from '.';
import { getCartAdjustment } from './utils/getCartAdjustment';
import { getCartSavings } from './utils/getCartSavings';
import { cartContainsFrame } from './utils/cartContainsFrame';
import { getDeliveryDueDate } from './utils/shipping/getDeliveryDueDate';
import { getDeliveryEstimates } from './utils/shipping/getDeliveryEstimates';

export class BaseCart
  implements
    Omit<Cart, 'events' | 'history' | 'payments' | 'refunds' | 'isPriority'> {
  public id;
  public email;
  public firstName;
  public lastName;
  public fullName;
  public phone;
  public billingAddress;
  public shippingAddress;
  public fulfillments;
  public shipments;
  public notes;
  public store;
  public status;
  public paid;
  public total;
  public meta;
  public adjustmentsTotal;
  public itemsTotal;
  public isApproved;
  public isPaid;
  public isEditable;
  public adjustments;
  public customer;
  public voucher;
  public approvedAt;
  public approvedBy;
  public createdAt;
  public updatedAt;
  public paidAt;
  public items: BaseCartItem[] = [];

  constructor(data?: Partial<BaseCart>) {
    Object.assign(this, data);
  }

  get isEmpty(): boolean {
    return !this.id || this.items.length < 1;
  }

  get isCancellable(): boolean {
    return (
      this.isStatus(CartStatus.Draft) &&
      !this.isPreapproved &&
      !this.isFulfilled &&
      !(this.hasDigitalPrint && this.isPreapproved)
    );
  }

  get isPendingCancellation(): boolean {
    return this.isStatus(CartStatus.PendingCancel);
  }

  get isCancelled(): boolean {
    return this.isStatus(CartStatus.Cancelled);
  }

  get isFulfilled(): boolean {
    return this.isStatus(CartStatus.Fulfilled);
  }

  get isCustomerHold(): boolean {
    return this.status === CartStatus.CustomerHold;
  }

  get isPreapproved(): boolean {
    return (
      (this.meta.isPreapproved && !this.isCustomerHold) ||
      (this.isApproved &&
        this.approvedBy === ApprovedBy.Customer &&
        !this.isCustomerHold)
    );
  }

  get totalPrintsQuantity(): number {
    let total = 0;
    this.getPrints().forEach(print => {
      total += print.quantity;
    });

    return total;
  }

  get isRushOrder(): boolean {
    return (this.items || []).some(item => item.isRushOrder);
  }

  get hasFrame(): boolean {
    return cartContainsFrame(this);
  }

  get hasHangerFrame(): boolean {
    return (this.items || []).some(item => item.sku.startsWith('HF'));
  }

  get hasDigitalPrint(): boolean {
    return (this.items || []).some(item => item.sku.indexOf('DIGITAL') > -1);
  }

  get hasFulfillableItems(): boolean {
    return (this.items || []).some(
      item => !item.isAddOn && !item.isDigitalPrint
    );
  }

  get hasBogo(): boolean {
    return !!(this.couponCode?.toLowerCase().indexOf('b2g1') > -1);
  }

  get couponCode(): string | null {
    return get(this.discount, 'meta.code')?.toUpperCase() ?? null;
  }

  get subtotal(): number {
    return get(this, 'itemsTotal', 0);
  }

  get taxAmount(): number {
    return get(this.tax, 'amount', 0);
  }

  get taxRate(): number {
    return get(this.tax, 'meta.rate', 0);
  }

  get tax(): Adjustment {
    return getCartAdjustment(this, AdjustmentType.Tax);
  }

  get discount(): Adjustment {
    return getCartAdjustment(this, AdjustmentType.Discount);
  }

  get shipping(): Adjustment {
    return getCartAdjustment(this, AdjustmentType.Shipping);
  }

  get shippingAmount(): number {
    return this.shipping?.amount ?? 0;
  }

  get shippingMethod(): string {
    return get(this.shipping, 'meta.method', null);
  }

  get shippingName(): string | null {
    return get(this.shipping, 'meta.name', null);
  }

  get shippingRange(): number[] | null {
    return get(this.shipping, 'meta.range', null);
  }

  get deliveryDueDate(): any {
    if (!this.shipping || !this.shippingAddress?.country) return null;

    if (this.shipping.meta?.deliveryDate) {
      return this.shipping.meta.deliveryDate;
    }

    return getDeliveryDueDate(
      this,
      this.shippingAddress?.country.toLowerCase()
    );
  }

  get formattedPaid(): string {
    return formatCurrency(this.paid || 0);
  }

  get formattedSavings(): string {
    const savings = getCartSavings(this);

    return formatCurrency(savings || 0);
  }

  get formattedSubtotal(): string {
    return formatCurrency(this.subtotal || 0);
  }

  get formattedTaxAmount(): string {
    return formatCurrency(this.taxAmount);
  }

  get formattedTaxRate(): string {
    return `${(this.taxRate * 100).toFixed(2)}%`;
  }

  get formattedTotal(): string {
    return formatCurrency(this.total || 0);
  }

  getPrints(): BaseCartItem[] {
    return this.items.filter(item => item.isPrint);
  }

  getFormattedSavings(): string {
    const savings = getCartSavings(this);

    return formatCurrency(savings || 0);
  }

  public async deliveryEstimates(country: string): Promise<any> {
    return getDeliveryEstimates(this, country);
  }

  private isStatus(status: CartStatus): boolean {
    return status === this.status;
  }
}
