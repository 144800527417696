import React, {
  createContext,
  ReactNode,
  useState,
  useEffect,
  useCallback,
} from 'react';
import * as Analytics from '@app/lib/analytics';
import { useShop } from '@app/lib/shop';
import { initialize } from './state/initialize';
import { deepmerge, setLocalStorage } from '@app/lib/utils';

const { NEXT_PUBLIC_LS_CACHE_KEY } = process.env;

export type AppStateProviderProps = { children?: ReactNode };
export type AppState = {
  config?: any;
  updateAppState?: (data: any) => void;
};

export const AppStateContext = createContext<AppState>(null);

export const AppStateProvider: React.FC = ({ children }) => {
  const shop = useShop();
  const [state, setState] = useState<AppState>(null);

  const updateAppState = useCallback(
    (state, payload) => {
      const data = deepmerge(state, payload);

      setState(data);
      setLocalStorage(NEXT_PUBLIC_LS_CACHE_KEY, data);
    },
    [state]
  );

  useEffect(() => {
    if (!state) {
      const initState = initialize(NEXT_PUBLIC_LS_CACHE_KEY);

      setState(initState);
      setLocalStorage(NEXT_PUBLIC_LS_CACHE_KEY, initState);
      Analytics.track('initialize', shop, initState);
    }
  }, [state]);

  return (
    <AppStateContext.Provider
      value={{
        config: state,
        updateAppState: data => updateAppState(state, data),
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
};
