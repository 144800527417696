import { formatCurrency, get } from '@app/lib/utils';
import { Item, ProductType } from '@app/lib/graphql/schema';

export interface ProductProps {
  name: string;
  description: string;
  footer?: string;
  size?: string;
  retailPrice?: number;
}

export const PRINT_CLIENT_URL = process.env.NEXT_PUBLIC_PRINT_CLIENT_URL;
export const PRINT_META_KEY = 'andalways:print';

const FRAME_SKU_SUFFIXES: string[] = ['FBLK', 'FWHT'];

const PRODUCT_PROPERTIES: Record<string, ProductProps> = {
  P12X16: {
    name: 'Custom Moon Map',
    description: 'Unframed',
    footer: 'Printed on 230 GSM Fine Art Paper',
    retailPrice: 5500,
  },
  P18X24: {
    name: 'Custom Moon Map',
    description: 'Unframed',
    footer: 'Printed on 230 GSM Fine Art Paper',
    retailPrice: 7000,
  },
  P24X36: {
    name: 'Custom Moon Map',
    description: 'Unframed',
    footer: 'Printed on 230 GSM Fine Art Paper',
    retailPrice: 12000,
  },
  'P18X24-6000': {
    name: 'Custom Moon Map',
    description: 'Unframed',
    footer: 'Printed on 230 GSM Fine Art Paper',
    retailPrice: 7000,
  },
  'P-DIGITAL': {
    name: 'Custom Digital Moon Map',
    description: 'Digital Version - PDF & PNG formats',
    footer: null,
    retailPrice: 4000,
  },
  'P12X16-FBLK': {
    name: 'Custom Moon Map',
    description: 'with Black Wood Frame',
    footer: 'Printed on 230 GSM Fine Art Paper',
    retailPrice: 13000,
  },
  'P12X16-FWHT': {
    name: 'Custom Moon Map',
    description: 'with White Wood Frame',
    footer: 'Printed on 230 GSM Fine Art Paper',
    retailPrice: 13000,
  },
  'P18X24-FBLK': {
    name: 'Custom Moon Map',
    description: 'with Black Wood Frame',
    footer: 'Printed on 230 GSM Fine Art Paper',
    retailPrice: 16000,
  },
  'P18X24-FBLK-13500': {
    name: 'Custom Moon Map',
    description: 'with Black Wood Frame',
    footer: 'Printed on 230 GSM Fine Art Paper',
    retailPrice: 16000,
  },
  'P18X24-FWHT': {
    name: 'Custom Moon Map',
    description: 'with White Wood Frame',
    footer: 'Printed on 230 GSM Fine Art Paper',
    retailPrice: 16000,
  },
  'P18X24-FWHT-13500': {
    name: 'Custom Moon Map',
    description: 'with White Wood Frame',
    footer: 'Printed on 230 GSM Fine Art Paper',
    retailPrice: 16000,
  },
  'P24X36-FBLK': {
    name: 'Custom Moon Map',
    description: 'with Black Wood Frame',
    footer: 'Printed on 230 GSM Fine Art Paper',
    retailPrice: 30000,
  },
  'P24X36-FWHT': {
    name: 'Custom Moon Map',
    description: 'with White Wood Frame',
    footer: 'Printed on 230 GSM Fine Art Paper',
    retailPrice: 30000,
  },
  'HF18-BLK': { name: 'Hanger Frame', description: 'Black - 18 inches' },
  'HF19-BLK': { name: 'Hanger Frame', description: 'Black - 19 inches' },
  'HF18-WHT': { name: 'Hanger Frame', description: 'White - 18 inches' },
  'HF19-WHT': { name: 'Hanger Frame', description: 'White - 19 inches' },
  'RSH-ORDER-199': {
    name: 'Rush Order',
    description:
      'This order will be prioritized and shipped the next business day.',
  },
};

export class BaseCartItem implements Item {
  public id;
  public sku;
  public name;
  public category;
  public unitPrice;
  public commodityCode;
  public upc;
  public quantity;
  public isPaid;
  public isRefunded;
  public total;
  public meta;
  public product;
  public addedAt;
  public metaUpdatedAt;

  constructor(data?: Partial<BaseCartItem>) {
    Object.assign(this, data);
  }

  get displayName(): string {
    return this.getProductProperty<string>('name');
  }

  get description(): string {
    return this.getProductProperty<string>('description');
  }

  get reminder(): string {
    return this.getProductProperty<string>('reminder');
  }

  get footerDetails(): string {
    return this.getProductProperty<string>('footer');
  }

  get retailPrice(): number {
    return this.getProductProperty<number>('retailPrice');
  }

  get formattedTotal(): string {
    return formatCurrency(this.total);
  }

  get formattedUnitPrice(): string {
    return formatCurrency(this.unitPrice);
  }

  get printMeta(): any {
    return get(this, `meta.${PRINT_META_KEY}.options`, null);
  }

  get printMetadata(): any {
    return get(this, `meta.${PRINT_META_KEY}`, null);
  }

  get isPrint(): boolean {
    return (
      this.sku?.toUpperCase().indexOf('P12X16') > -1 ||
      this.sku?.toUpperCase().indexOf('P18X24') > -1 ||
      this.sku?.toUpperCase().indexOf('P24X36') > -1 ||
      this.isDigitalPrint
    );
  }

  get isDigitalPrint(): boolean {
    return this.product?.type === ProductType.Digital;
  }

  get isRushOrder(): boolean {
    return this.sku?.indexOf('RSH-ORDER') > -1;
  }

  get isAddOn(): boolean {
    return this.category === 'addons';
  }

  get isFramed(): boolean {
    let hasFrame = false;

    FRAME_SKU_SUFFIXES.forEach(suffix => {
      if (this.sku.toLowerCase().indexOf(suffix.toLowerCase()) > -1)
        hasFrame = true;
    });

    return hasFrame;
  }

  getPrintMessageExcerpt(numOfWords = 4): any {
    const headline = get(this.printMeta, 'theme.headline', undefined);

    if (!headline) return undefined;

    const wordCount = headline.split(/\s+/).length;
    let message = headline.split(/\s+/).slice(0, 4).join(' ');

    if (wordCount > numOfWords) {
      message += '...';
    }

    return message;
  }

  private getProductProperty<T>(key: string): T | null {
    return PRODUCT_PROPERTIES[this.sku]?.[key] ?? null;
  }
}

export default BaseCartItem;
