/*
 *  2020 Shipping Holidays
 */
export const SHIPPING_HOLIDAYS: Date[] = [
  new Date(2021, 0, 1), // New Year's Day
  new Date(2021, 0, 20), // Martin Luther King Jr. Day
  new Date(2021, 1, 17), // President's Day
  new Date(2021, 4, 25), // Memorial Day
  new Date(2021, 6, 3), // Independence Day
  new Date(2021, 8, 6), // Labor Day
  new Date(2021, 9, 11), // Columbus Day
  new Date(2021, 10, 11), // Veterans Day
  new Date(2021, 10, 25), // Thanksgiving Day
  new Date(2020, 11, 24), // Christmas Eve
  new Date(2020, 11, 25), // Christmas Day
  new Date(2021, 11, 31), // New Years Eve
];
