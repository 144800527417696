import qs from 'qs';
import { get, has, deepmerge } from '@app/lib/utils';

export const settingsFromLocalStorage = (
  settings: any, //eslint-disable-line
  localStorageKey: any //eslint-disable-line
): any => {
  let storage = '';
  let storageOld = '';

  if (typeof window !== 'undefined') {
    storage = (localStorage.getItem(localStorageKey) as string) || '{}';
    storageOld = (localStorage.getItem('tit_config') as string) || '{}';
  }

  const parsedStorage = deepmerge(JSON.parse(storage), JSON.parse(storageOld));
  const params: any = {};

  Object.keys(settings).forEach((rootKey: string) => {
    params[rootKey] = {};

    Object.keys(settings[rootKey]).forEach(childKey => {
      const setting = settings[rootKey][childKey];

      if (has(parsedStorage, [rootKey, childKey])) {
        let value = get(parsedStorage, [rootKey, childKey]);
        if (value === 'true') value = true;

        params[rootKey][childKey] = setting.transform
          ? setting.transform(value)
          : value;
      } else {
        if (setting.default !== null)
          params[rootKey][childKey] = setting.default;
      }
    });
  });

  return params;
};

export const settingsFromParameters = (
  settings: any //eslint-disable-line
): any => {
  const search = get(window, 'location.search', '');
  const parsedParameters = qs.parse(search.split('?')[1], {
    ignoreQueryPrefix: true,
  });
  const params: any = {};

  Object.keys(settings).forEach((rootKey: string) => {
    params[rootKey] = {};

    Object.keys(settings[rootKey]).forEach(childKey => {
      const setting = settings[rootKey][childKey];

      if (has(parsedParameters, setting.key)) {
        let value = get(parsedParameters, setting.key);
        if (value === 'true') value = true;

        params[rootKey][childKey] = setting.transform
          ? setting.transform(value)
          : value;
      }
    });
  });

  return params;
};
