import { get } from '@app/lib/utils';
import { ApolloLink } from '@apollo/client';
import { plainToCartClass } from '@app/lib/graphql/utils';

// operations that return `Cart`
// allows analytics easy access to calculated cart props
const operationsToTransform = [
  'cart',
  'cartFromFulfillment',
  'createCart',
  'addItemsToCart',
  'addAdjustmentToCart',
  'removeItemsFromCart',
  'updateAdjustmentForCart',
  'updateCart',
  'updateCartItem',
  'chargeBraintree',
  'applyVoucher',
  'removeVoucher',
];

export const responseToModel = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    const responseDataKey = operation?.operationName;

    if (
      operationsToTransform.indexOf(responseDataKey) > -1 &&
      !response?.errors
    ) {
      response.data[responseDataKey] = plainToCartClass(
        get(response, `data.${responseDataKey}`, {})
      );
    }

    return response;
  });
});
