import LocalStorageCache from '@andalways/localstorage-cache';
import { v4 as uuid } from 'uuid';
import { Analytics } from './Analytics';
import { SessionParameters } from './SessionParameters';

export const SessionCache = new SessionParameters();
const AnalyticsCache = new LocalStorageCache();

let analytics: Analytics;
let anonymousId: string = initAnonymousId();

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function track(method: string, ...args: any): void {
  if (typeof window !== 'undefined' && user()) {
    getAnalytics()[method].apply(analytics, args);
  }
}

export function user(): any {
  if (typeof window === 'undefined') return null;

  return typeof window?.analytics?.user === 'function'
    ? (<any>window).analytics.user()
    : null;
}

function getAnalytics(): Analytics {
  if (typeof analytics !== 'object') {
    analytics = new Analytics();
  }

  return analytics;
}

export function getAnonymousId(): string {
  if (typeof anonymousId !== 'string') {
    anonymousId = uuid();
  }

  AnalyticsCache.set('aa_anonymous_id', anonymousId);

  return anonymousId;
}

export function initAnonymousId(): string {
  return (
    AnalyticsCache.get('ajs_anonymous_id') ??
    AnalyticsCache.get('aa_anonymous_id')
  );
}
