import React, { memo } from 'react';
import { LoaderIcon, Wrapper } from './Loader.styles';

export interface LoaderProps {
  fullWidth?: boolean;
  size?: number;
  white?: boolean;
}

export const Loader: React.FC<LoaderProps> = memo(
  ({ fullWidth = false, size = 24, white = false }) => (
    <Wrapper fullWidth={fullWidth}>
      <LoaderIcon size={size} white={white} />
    </Wrapper>
  )
);
