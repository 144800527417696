import qs from 'qs';
import LocalStorageCache from '@andalways/localstorage-cache';

// Destructuring throws 'process undefined' error
const NEXT_PUBLIC_LS_TRACKING_PARAMS_KEY =
  process.env.NEXT_PUBLIC_LS_TRACKING_PARAMS_KEY;
const NEXT_PUBLIC_LS_TRACKING_PARAM_STATUS_KEY =
  process.env.NEXT_PUBLIC_LS_TRACKING_PARAM_STATUS_KEY;

const isBrowser = typeof window !== 'undefined';
const SessionCache = new LocalStorageCache({ type: 'session' });

export class SessionParameters {
  public readonly storageKey: string = NEXT_PUBLIC_LS_TRACKING_PARAMS_KEY;
  public readonly storageKeyDsnStatus: string = NEXT_PUBLIC_LS_TRACKING_PARAM_STATUS_KEY;

  constructor() {
    if (!isBrowser) return;

    this.init();
  }

  public init(): void {
    if (SessionCache.get(this.storageKey)) {
      return;
    }

    const urlParams = qs.parse(window?.location?.search || '', {
      ignoreQueryPrefix: true,
    });
    const isParamsEmpty = Object.keys(urlParams).length < 1;

    if (isParamsEmpty) {
      urlParams['utm_source'] = 'direct';
      urlParams['aa_source'] = 'direct';
    }

    // set aa_source to utm_source if doesnt exist
    if (urlParams?.['utm_source'] && !urlParams?.['aa_source']) {
      urlParams['aa_source'] = urlParams['utm_source'];
    }

    SessionCache.set('aa_parameters', urlParams);
  }

  public isSourceDirect(): any {
    const params = this.getStorageParams();

    return (
      params['utm_source'] === 'direct' || params['aa_source'] === 'direct'
    );
  }

  public getStorageParams(): any {
    return SessionCache.get(this.storageKey) || {};
  }

  public getHasUpdatedDsn(): boolean {
    return SessionCache.get(this.storageKeyDsnStatus) || false;
  }

  public setHasUpdatedDsn(value: boolean): void {
    SessionCache.set(this.storageKeyDsnStatus, value);
  }
}
