import qs from 'qs';
import InitialState from './defaults';
import { deepmerge, getLocalStorage } from '@app/lib/utils';
import { settingsFromParameters, settingsFromLocalStorage } from './helpers';

export const initialize = (localStorageKey: string): any => {
  const parametersConfig = settingsFromParameters(InitialState);
  const localStorageConfig = settingsFromLocalStorage(
    InitialState,
    localStorageKey
  );

  const combinedState: any = deepmerge.all([
    parametersConfig,
    localStorageConfig,
  ]);

  combinedState.parameters = initParameters(localStorageKey);

  return combinedState;
};

const initParameters = (localStorageKey: string): any => {
  const storage = getLocalStorage(localStorageKey);
  const storageParameters = storage?.parameters || {};
  const urlParameters = qs.parse(window?.location.search || '', {
    ignoreQueryPrefix: true,
  });

  if (urlParameters.fbclid) {
    storageParameters.fbcTime = new Date().getTime();
  }

  return deepmerge.all([storageParameters, urlParameters]);
};
