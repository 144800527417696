import dayjs from 'dayjs';
import { SHIPPING_HOLIDAYS } from '@app/config/shipping/holidays';
import { isSaturday, isSunday } from './isDates';

export const getDeliveryDate = (
  productionDate: dayjs.Dayjs,
  deliveryDateOffset: number,
  method: string
): string => {
  let deliveryDate = productionDate.add(deliveryDateOffset, 'day');

  // // if SUNDAY set MONDAY
  // if (isSunday(deliveryDate)) deliveryDate = deliveryDate.add(1, "day");

  // // if SATURDAY & NOT EXPRESS SHIPPING set MONDAY
  // // EXPRESS shipping delivers SATURDAYS
  // if (isSaturday(deliveryDate) && method !== "express")
  //   deliveryDate = deliveryDate.add(2, "day");

  // if DELIVERY_DATE === HOLIDAY, increase DELIVERY_DATE++
  SHIPPING_HOLIDAYS.forEach(holiday => {
    if (deliveryDate.isSame(dayjs(holiday), 'day')) {
      deliveryDate = deliveryDate.add(1, 'day');
    }

    // if SUNDAY set MONDAY
    if (isSunday(deliveryDate)) deliveryDate = deliveryDate.add(1, 'day');

    // if SATURDAY & NOT EXPRESS SHIPPING set MONDAY
    // EXPRESS shipping delivers SATURDAYS
    if (isSaturday(deliveryDate) && method !== 'express')
      deliveryDate = deliveryDate.add(2, 'day');
  });

  // if (isSaturday(deliveryDate) && method !== "express")
  //   deliveryDate = deliveryDate.add(2, "day");

  // if (isSunday(deliveryDate)) deliveryDate = deliveryDate.add(1, "day");

  return deliveryDate.toISOString();
};

export default getDeliveryDate;
