import { get } from '@app/lib/utils';
import { BaseCart } from '@app/lib/graphql/models';

const frameSkus = [
  'P12X16-FBLK',
  'P12X16-FWHT',
  'P18X24-FBLK',
  'P18X24-FWHT',
  'P24X36-FBLK',
  'P24X36-FWHT',
];

export const cartContainsFrame = (cart: BaseCart): boolean => {
  if (!cart) return false;

  const items = get(cart, 'items', []);
  let containsFrame = false;

  for (let i = 0; i < items.length; i++) {
    if (frameSkus.includes(items[i].sku)) {
      containsFrame = true;

      break;
    }
  }

  return containsFrame;
};
