import React, { useEffect } from 'react';
import { SplitFactory, SplitSdk } from '@splitsoftware/splitio-react';
import { getUserId } from './utils';

const { NEXT_PUBLIC_SPLIT_IO_API_KEY } = process.env;

export let factory: SplitIO.ISDK;
export let client: SplitIO.IClient;

// Using a function to get these variables
// forces a Singleton pattern on the factory and client.
export const getSplitFactoryAndClient = (): {
  factory: SplitIO.ISDK;
  client: SplitIO.IClient;
} => {
  if (!factory) {
    factory = SplitSdk({
      core: {
        authorizationKey: NEXT_PUBLIC_SPLIT_IO_API_KEY,
        key: getUserId(),
      },
      storage: {
        type: 'LOCALSTORAGE',
      },
    });
  }

  if (!client) {
    client = factory.client();
  }

  return { factory, client };
};

export const SplitProvider: React.FC = props => {
  const { factory } = getSplitFactoryAndClient();

  useEffect(() => {
    return () => {
      client?.destroy();
    };
  }, []);

  return (
    <SplitFactory factory={factory} updateOnSdkTimedout={true}>
      {props.children as any}
    </SplitFactory>
  );
};
