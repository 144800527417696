import { getSplitFactoryAndClient } from '@app/contexts/split-io';

export interface ShippingRateOrderType {
  fastest: number;
  slowest: number;
  holidayOffset: number;
  price: number;
}

export type ShippingRateCountries = 'us' | 'au' | 'nz' | 'other';
export interface ShippingRate {
  name: string;
  method: string;
  key: string;
  rates: {
    [K in ShippingRateCountries]: {
      framed: ShippingRateOrderType | null;
      unframed: ShippingRateOrderType;
    };
  };
}

/*
 *  Shipping Rates / Prices / Times (in days) by country
 */
export const SHIPPING_RATES: ShippingRate[] = [
  {
    name: 'Standard',
    method: 'STANDARD',
    key: 'STANDARD',
    rates: {
      us: {
        framed: { fastest: 8, slowest: 12, holidayOffset: 0, price: 0 },
        unframed: { fastest: 7, slowest: 12, holidayOffset: 0, price: 0 },
      },
      au: {
        framed: { fastest: 7, slowest: 15, holidayOffset: 0, price: 0 },
        unframed: { fastest: 7, slowest: 15, holidayOffset: 0, price: 0 },
      },
      nz: {
        framed: { fastest: 7, slowest: 15, holidayOffset: 0, price: 0 },
        unframed: { fastest: 7, slowest: 15, holidayOffset: 0, price: 0 },
      },
      other: {
        framed: { fastest: 10, slowest: 20, holidayOffset: 0, price: 0 },
        unframed: { fastest: 10, slowest: 20, holidayOffset: 0, price: 0 },
      },
    },
  },
  {
    name: 'Priority',
    method: 'PRIORITY',
    key: 'PRIORITY',
    rates: {
      us: {
        framed: { fastest: 3, slowest: 7, holidayOffset: -3, price: 3500 },
        unframed: { fastest: 3, slowest: 7, holidayOffset: -4, price: 1000 },
      },
      au: null,
      nz: null,
      other: null,
    },
  },
  {
    name: 'Express',
    method: 'EXPRESS',
    key: 'EXPRESS',
    rates: {
      us: {
        framed: { fastest: 2, slowest: 4, holidayOffset: -2, price: 6500 },
        unframed: { fastest: 2, slowest: 4, holidayOffset: -2, price: 2500 },
      },
      au: null,
      nz: null,
      other: null,
    },
  },
];

export const SHIPPING_RATES_ALT: ShippingRate[] = [
  {
    name: 'Standard',
    method: 'STANDARD',
    key: 'STANDARD',
    rates: {
      us: {
        framed: { fastest: 8, slowest: 12, holidayOffset: 0, price: 0 },
        unframed: { fastest: 7, slowest: 12, holidayOffset: 0, price: 0 },
      },
      au: {
        framed: { fastest: 7, slowest: 15, holidayOffset: 0, price: 0 },
        unframed: { fastest: 7, slowest: 15, holidayOffset: 0, price: 0 },
      },
      nz: {
        framed: { fastest: 7, slowest: 15, holidayOffset: 0, price: 0 },
        unframed: { fastest: 7, slowest: 15, holidayOffset: 0, price: 0 },
      },
      other: {
        framed: { fastest: 10, slowest: 20, holidayOffset: 0, price: 0 },
        unframed: { fastest: 10, slowest: 20, holidayOffset: 0, price: 0 },
      },
    },
  },
  {
    name: 'Priority',
    method: 'PRIORITY',
    key: 'PRIORITY',
    rates: {
      us: {
        framed: { fastest: 3, slowest: 7, holidayOffset: -3, price: 3500 },
        unframed: { fastest: 3, slowest: 7, holidayOffset: -4, price: 1000 },
      },
      au: null,
      nz: null,
      other: null,
    },
  },
  {
    name: 'Express',
    method: 'EXPRESS',
    key: 'EXPRESS',
    rates: {
      us: {
        framed: { fastest: 2, slowest: 4, holidayOffset: -2, price: 6500 },
        unframed: { fastest: 2, slowest: 4, holidayOffset: -2, price: 2500 },
      },
      au: null,
      nz: null,
      other: null,
    },
  },
];

export const getShippingRates = async (): Promise<ShippingRate[]> => {
  const { client } = getSplitFactoryAndClient();
  const treatment = await client.getTreatment('Shipping_Time' as any);

  return treatment === 'on' ? SHIPPING_RATES_ALT : SHIPPING_RATES;
};
