import { getLocalStorage, setLocalStorage } from '@app/lib/utils';

export function getCartFromLocalStorage(key: string): any {
  // backwards compat shit
  if (typeof window !== 'undefined') {
    const oldCartId = window.localStorage.getItem(key);

    if (
      typeof oldCartId === 'string' &&
      oldCartId.length > 0 &&
      oldCartId[0] !== '"'
    ) {
      setLocalStorage(key, oldCartId);

      return oldCartId;
    }
  }

  return getLocalStorage(key);
}
