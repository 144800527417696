import { Adjustment, AdjustmentType } from '@app/lib/graphql/schema';
import { get } from '@app/lib/utils';
import { BaseCart } from '../BaseCart';

export const getCartAdjustment = (
  cart: BaseCart,
  type: AdjustmentType
): Adjustment | null => {
  if (!cart) return null;

  const adjustments = get(cart, 'adjustments', []);
  let adjustment = null;

  for (let i = 0; i < adjustments.length; i++) {
    if (adjustments[i].type === type) {
      adjustment = adjustments[i];

      break;
    }
  }

  return adjustment;
};
