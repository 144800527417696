const initConfig = {
  app: {
    country: {
      name: 'Region',
      key: 'region',
      default: 'us',
      value: null,
      transform: null,
    },
    showCovidMsg: {
      name: 'Show Covid Message',
      key: 'showCovidMsg',
      default: false,
      value: null,
      transform: null,
    },
    hasShownCreateModal: {
      name: 'Creator questionaire has been shown.',
      key: 'hasShownCreateModal',
      default: false,
      value: null,
      transform: null,
    },
  },
  user: {
    gender: {
      name: 'Gender',
      key: 'g',
      default: null,
      value: null,
      transform: value => {
        if (value === '1') return 'male';
        if (value === '2') return 'female';

        return value;
      },
    },
    hasAddedFirstProduct: {
      name: 'Has added first product to order.',
      key: 'hasAddedFirstProduct',
      default: false,
      value: null,
      transform: null,
    },
    initialParameters: {
      name: 'Initial url parameters from user',
      key: null,
      default: false,
      value: null,
      transform: null,
    },
  },
};

export default { ...initConfig };
